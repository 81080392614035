<template>
  <div v-if="steps">
    <progressBar :percent="Math.round((currentStep / (steps.length - 1) ) * 100)" />
    <div class="flix-form-group" v-if="currentStep">
      <a href="#" class="flix-html-a flix-html-small" @click.prevent="setStepBack()"><flixIcon :id="'arrow-left'"/> {{ $t('message.back') }} ({{ steps[currentStep - 1].title }})</a>
    </div>
    <transition name="flixFadeIn">
      <component :key="currentStep" :is="steps[currentStep].component" :data="assistent" :services="services" :callback="setSave" />
    </transition>

    <ol class="flix-breadcrumb">
      <breadcrumb v-for="(step, index) in steps" :key="index+currentStep" :show="[currentStep, index]" :saved="getSaved" :callback="setCurrentStep">
        <template v-slot:badge><span v-if="!step.check">{{ index + 1 }}</span></template>
        <template v-slot:title>{{ step.title }}</template>
      </breadcrumb>
    </ol>
    <span v-if="currentStep !== 0">
      <destroyPopup v-if="steps" :step="currentStep" :steps="steps.length" />
    </span>
  </div>
</template>
<script>
export default {
  components: {
    destroyPopup () { return import('@/components/assistent/components/assets/destroyPopup') },
    progressBar () { return import('@/components/assistent/components/assets/progressbar') },
    breadcrumb () { return import('@/components/assistent/components/assets/breadcrumb') },
    calendarFormat () { return import('@/components/assistent/components/calendarFormat') },
    timeRange () { return import('@/components/assistent/components/timeRange') },
    appointments () { return import('@/components/assistent/components/appointments') },
    settings () { return import('@/components/assistent/components/settings') },
    formBuilder () { return import('@/components/assistent/components/form') },
    preview () { return import('@/components/assistent/components/preview') }

  },
  props: { data: Object, services: Array },
  data () {
    return {
      id: new Date().getTime(),
      percent: 0,
      assistent: this.getAssistent(),
      currentStep: 0,
      steps: false
    }
  },
  watch: {
    '$i18n.locale' () { this.getSteps() }
  },
  methods: {
    setCurrentStep (id) {
      this.currentStep = id
    },
    getSaved () {
      if (this.assistent.form.length) {
        return true
      }
      return false
    },
    setStepBack () {
      this.currentStep -= 1
    },
    getSteps () {
      this.steps = [
        { title: this.$t('message.calendarFormat'), check: false, component: 'calendarFormat' },
        { title: this.$t('message.timeRange'), check: false, component: 'timeRange' },
        { title: this.$tc('message.appointmentRequests', 2), check: false, component: 'appointments' },
        { title: this.$t('message.additionalSettings'), check: false, component: 'settings' },
        { title: this.$tc('message.form', 1), check: false, component: 'formBuilder' },
        { title: this.$t('message.preview'), check: false, component: 'preview' }
      ]
    },
    getAssistent () {
      if (this.data && typeof this.data === 'object' && Object.keys(this.data).length) {
        return this.data
      }
      return {
        type: '',
        styling: {
          accent: '#8dc044'
        },
        startBtn: this.$t('message.startBtnTxt'),
        contactName: '',
        contactEmail: '',
        exclude: {
          weekdays: {},
          dates: [],
          lastminute: ''
        },
        autoconfirm: false,
        verifyTime: 2,
        include: {
          buffer: [0, 0, 0, 0, 0, 0, 0],
          weekdays: {},
          period: []
        },
        events: [],
        next_appointments: {},
        max_applicants: {},
        showAppointments: false,
        bookingMethod: 'default',
        title: '',
        start: '',
        end: '',
        date: false,
        form: []
      }
    },
    setSave (data) {
      this.assistent = Object.assign(this.assistent, data)

      // window.console.log(data)

      if (this.assistent.type) {
        this.currentStep = 1
      }
      if (this.assistent.type === 'datepicker' && Object.keys(this.assistent.include.weekdays).length) {
        this.currentStep = 2
      }
      if (this.assistent.type === 'eventpicker' && Object.keys(this.assistent.events).length) {
        this.currentStep = 2
      }
      if (this.assistent.type === 'datepicker' && Object.keys(this.assistent.max_applicants).length) {
        this.currentStep = 3
      }
      if (this.assistent.type === 'eventpicker' && typeof this.assistent.max_applicants[0] !== 'undefined' && this.assistent.max_applicants[0]) {
        this.currentStep = 3
      }
      if (this.assistent.type === 'eventpicker' && typeof this.assistent.exclude.lastminute !== 'undefined' && this.assistent.exclude.lastminute !== '') {
        this.currentStep = 4
      }
      if (this.assistent.type === 'datepicker' && Object.keys(this.assistent.include.period).length) {
        this.currentStep = 4
      }
      if (Object.keys(this.assistent.form).length) {
        this.currentStep = 5
      }
    }
  },
  mounted () {
    this.getSteps()
    this.setSave({})
  },
  computed: {

  }
}
</script>
